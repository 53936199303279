html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #fff;
  font-family: Microsoft YaHei,-apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  font-size: 14px;
  color: #000;
}
* {
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
ul {
  list-style: none;
}
.swiper-container {
  width: 1200px;
  height: 660px;
}

.h5_slider .swiper-container {
  position: relative;
  width: 86.67vw;
  height: 112.vw;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.h5_slider .swiper-slide{
  width: 86.67vw;;
}

.swiper-pagination-bullet {
  width: 12px!important;
  height: 12px!important;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  text-indent: -9999em;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: linear-gradient(123deg, #64C7F7 0%, hsl(217, 100%, 58%) 100%) !important;
  width: 40px!important;
  border-radius: 6px !important;
}

@keyframes action{
  100%{
    transform: scale(8);;
    -ms-transform: scale(8);;
    -webkit-transform: scale(8);;
  }
}

@-webkit-keyframes action {
  100%{
    transform: scale(8);;
    -ms-transform: scale(8);;
    -webkit-transform: scale(8);;
  }
}


.mapIcon{
  width: 40px;
  height: 40px;
  background: url(./imgs/mapIcon.png) center / cover no-repeat;
  position: relative;
  z-index: 10;
}

.swiper-button-prev,.swiper-button-next{
  top:32% !important;
  color:red!important;
}
.swiper-button-prev{
  margin-left: -4vw;
}
.swiper-button-next{
  margin-right: -4vw;
}

.swiper-button-prev::after,.swiper-button-next::after{
  font-size: 18px!important;
  color:#3483FF!important;
}
.swiper-button-disabled::after{
  color: gray !important;
}
.amap-logo,.amap-copyright{
  display: none !important;
}

body{ -webkit-tap-highlight-color:rgba(0,0,0,0); }

a:focus,
input:focus,
p:focus,
li:focus,
div:focus{
-webkit-tap-highlight-color:rgba(0,0,0,0);
-webkit-user-modify:read-write-plaintext-only; 
}